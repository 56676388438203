<template>
  <div>
    <v-select
      :items="values_condition"
      label="Valor"
      item-text="display_name"
      item-value="id"
      dense
      outlined
      v-model="option"
      :rules="[v => !!v || 'Campo obrigatório']"
      @change="setValue"
      :loading="loading"
    >
    </v-select>
  </div>
</template>
<script>
import {mapState, mapMutations, mapActions, mapGetters} from "vuex";

export default {
  props: {
    index: {},
    index_condition: {},
    type_condition: {
      type: String,
      default: '',
    },
    type_value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: true,
      condition_type_values: [],
      value_selected: "",
      option: "",
      values_condition: []
    };
  },
  async mounted() {
    this.solicitation_conditions.forEach(condition => {
      if (condition.value === this.type_condition) {
        this.values_condition = condition.values;
      }
    });
    this.option = this.type_value;
    // if (this.type_condition && this.solicitation_conditions[this.type_condition].values) {
    //   this.values_condition = this.solicitation_conditions[this.type_condition].values;
    //   this.option = this.type_value;
    // }
    this.loading = false;
  },

  computed: {
    ...mapState("rules", ["rules", "solicitation_conditions"]),
    ...mapGetters("rules", ["getStatus", "getSelectedStatus"]),
  },
  methods: {
    ...mapMutations({ setRules: "rules/setRules" }),
    ...mapActions("rules", ["loadSolicitationConditions"]),

    setValue(data) {
      this.rules[this.index].conditions[
        this.index_condition
      ].type_value = data;
      this.setRules(this.rules);
    }
  },

  watch: {
    rules: {
      deep: true,
      handler() {
        this.solicitation_conditions.forEach(condition => {
          if (condition.value === this.type_condition) {
            this.values_condition = condition.values;
          }
        });
      }
    }
  }
};
</script>
