<template>
  <div>
    <div v-if="cardContentLoading" class="text-center ma-5">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-if="!cardContentLoading" class="pt-0 pb-2">
      <v-card class="px-2 pt-2 pb-0">
        <v-select
          :items="list_actions_types"
          item-text="display_name"
          item-value="id"
          label="Tipo de Ação"
          dense
          outlined
          :rules="[v => !!v || 'Campo obrigatório']"
          v-model="action_type"
          @change="setActionType"
          :loading="loadingActions"
          :disabled="loadingActions"
        ></v-select>
        <v-row>
          <v-col class="py-0">
            <component
              :is="actionTypeSelected.name || 'selectAction'"
              :index="index"
              :index_actions="index_actions"
              :actionTypeSelected="actionTypeSelected"
              :rules_list="rules_list"
              :selectedId="selectedId"
            />
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import updateStatus from "./updateStatusComponent";
import addFlag from "./addFlagComponent";
import sendEmail from "./sendEmailComponent";
import sendEmailRequester from "./sendEmailRequesterComponent";
import sendEmailValidator from "./sendEmailValidatorComponent";
import sendEmailApprover from "./sendEmailApproverComponent";
import sendWebhook from "./sendWebhookComponent";
import selectAction from "./selectActionComponent";

export default {
  components: {
    selectAction,
    sendEmail,
    sendEmailRequester,
    sendEmailValidator,
    sendEmailApprover,
    sendWebhook,
    updateStatus,
    addFlag,
  },
  props: ["index", "index_actions", "rules_list", "selectedId"],
  data() {
    return {
      action_type: "",
      action_types: [],
      list_actions_types: [],
      actionTypeSelected: {},
      loadingActions: true,
      cardContentLoading: true
    };
  },

  async mounted() {
    await this.loadActionTypes().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
    this.list_actions_types = this.action_type_list.data;
    this.action_type = this.rules[this.index].actions[this.index_actions].type;
    if (this.action_type != "") {
      await this.setActionType(this.action_type);
    }
    this.loadingActions = false;
    this.cardContentLoading = false;
  },

  computed: {
    ...mapState("rules", ["rules", "messageRules", "action_type_list"])
  },

  methods: {
    ...mapActions("rules", ["loadRules"]),
    ...mapActions("rules", ["loadActionTypes"]),
    ...mapMutations({ setRules: "rules/setRules" }),

    setActionType(value) {
      if (!value) {
        value = "";
      }
      this.rules[this.index].actions[this.index_actions].type = value;
      this.setRules(this.rules);

      this.actionTypeSelected = this.list_actions_types.find(
        actionType => actionType.id === value
      );
    }
  }
};
</script>
