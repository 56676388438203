<template>
  <div>
    <v-row>
      <v-col cols="12" class="py-0">
        <v-autocomplete
          v-model="statusSelected"
          label="Novo status da Solicitação"
          outlined
          :items="statusSolicitationList"
          item-text="display_name"
          item-value="id"
          @change="updateFields"
          dense
          clearable
          :rules="[v => !!v || 'Obrigatório']"
          :disabled="loading"
        >
        </v-autocomplete>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="py-0">
        <v-textarea
          v-model="interactionContent"
          label="Conteúdo da interação"
          outlined
          dense
          @change="updateFields"
          :loading="loading"
        ></v-textarea>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapMutations, mapGetters, mapState, mapActions } from "vuex";

export default {
  components: {},
  props: ["index", "index_actions"],
  data() {
    return {
      loading: true,
      statusSelected: "",
      statusSolicitationList: [
        {
          id: 'c5bcd554-b188-4f76-9bc7-e5a105a6b79f',
          display_name: 'Cancelar',
        },
        {
          id: '7c4b1588-07b7-4047-aae8-39db6870eb68',
          display_name: 'Finalizado',
        },
      ],
      interactionContent: "",
    };
  },
  async mounted() {
    let rules = this.rules[this.index].actions[this.index_actions].fields;
    if (rules.status_id) {
      this.statusSelected = rules.status_id;
      this.interactionContent = rules.interaction_content;
    }
    this.loading = false;
  },
  computed: {
    ...mapState("rules", ["rules"]),
    ...mapGetters("rules", ["getRules"]),
  },
  methods: {
    ...mapMutations({ setRules: "rules/setRules" }),

    updateFields() {
      this.rules[this.index].actions[this.index_actions].fields.status_id = this.statusSelected;
      this.rules[this.index].actions[this.index_actions].fields.interaction_content = this.interactionContent;
      this.setRules(this.rules);
    },
  }
};
</script>
