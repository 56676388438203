<template>
  <v-form ref="validForm">
  <v-row justify="center">
    <v-dialog v-model="dialogRules" max-width="1200px" persistent>
      <div>
        <v-card>
          <v-toolbar flat color="primary" dark>
            <v-toolbar-title>
              Regras do Tipo de Solicitação
              {{ solicitationName }}</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <button type="button" class="close" @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </button>
          </v-toolbar>
          <v-tabs>
            <v-tab v-for="tab in tabRules" :key="tab.name">
              <v-icon left> {{ tab.icon }} </v-icon>
              {{ tab.name }}
            </v-tab>

      <!--  NOTIFICAÇÕES    -->
            <v-tab-item class="pa-3 pr-7">
              <div v-if="tabsLoading" class="text-center ma-5">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </div>
              <div v-if="!rules_list.length && !tabsLoading" class="ma-0 pa-0">
                <div class="text-right mr-5 pa-0">
                  <v-btn elevation="1" color="primary" @click="addRule()">Nova Notificação</v-btn>
                </div>
                <v-row class="mt-1 ml-1 mr-1">
                  <v-col class="cols-md-12">
                    <v-alert class="mt-5" border="left" dense colored-border type="warning"
                    >Este tipo de solicitação ainda não possui regras cadastradas!
                    </v-alert>
                  </v-col>
                </v-row>
              </div>
              <div v-else>
                <div class="text-right mb-5 mt-0 pa-0" v-if="!tabsLoading">
                  <v-btn elevation="1" color="primary" @click="addRule()">Nova Notificação</v-btn>
                </div>
                <v-expansion-panels multiple v-model="expandedPanels" class="ma-2">
                  <v-expansion-panel v-for="(rule, index_rule) in rules_list" :key="index_rule">
                    <v-expansion-panel-header>
                      <v-btn class="form-title" :retain-focus-on-click="false" :ripple="false" color="primary" text
                      >{{ createTitle(rule) }}
                      </v-btn>
                    </v-expansion-panel-header>
                    <v-divider></v-divider>
                    <v-expansion-panel-content>
                      <div>
                        <div class="text-right mb-1 mt-3">
                          <v-btn elevation="1" color="error" @click="removeRules(index_rule)">Excluir Regra!</v-btn>
                        </div>
                        <ListConditionsComponent :index="index_rule" :conditions_list="rule.conditions" />
                        <ListActionsComponent :key="`actions-${actionsKey}`" :index="index_rule" :rules_list="rules_list" :selectedId="selectedId" />
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </v-tab-item>

<!--        VALIDADORES    -->
            <v-tab-item class="pa-3 pr-7">
              <div v-if="tabsLoading" class="text-center ma-5">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </div>
              <div v-if="!validator_rules_list.length && !tabsLoading">
                <div class="text-right mb-5 pr-0">
                  <v-btn elevation="1" color="primary" @click="addValidatorRule()">Nova regra de validadores</v-btn>
                </div>
                <v-row class="mt-1 ml-1 mr-1">
                  <v-col class="cols-md-12">
                    <v-alert class="mt-5" border="left" dense colored-border type="warning"
                      >Este tipo de solicitação ainda não possui regras de validadores cadastradas!
                    </v-alert>
                  </v-col>
                </v-row>
              </div>
              <div v-else>
                <div class="text-right mb-5 pr-0" v-if="!tabsLoading">
                  <v-btn elevation="1" color="primary" @click="addValidatorRule()">Nova Regra De Validador</v-btn>
                </div>
                <v-expansion-panels
                  multiple
                  v-for="(validator_rule, index_validator) in validator_rules_list"
                  :key="index_validator"
                  class="ma-2"
                >
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <v-btn class="form-title" :retain-focus-on-click="false" :ripple="false" color="primary" text
                        >{{
                          validator_rule.values.description ? validator_rule.values.description : "Nova regra de validador"
                        }}
                      </v-btn>
                    </v-expansion-panel-header>
                    <v-divider></v-divider>
                    <v-expansion-panel-content>
                      <ValidatorRules
                        :selectedId="selectedId"
                        :validator_rules_list="validator_rules_list"
                        :index_validator="index_validator"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </v-tab-item>

<!--        APROVADORES    -->
            <v-tab-item class="pa-3 pr-7">
              <div v-if="tabsLoading" class="text-center ma-5">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </div>
              <div v-if="!solicitationTypeApprovalRules.length && !tabsLoading">
                <div class="text-right mb-5 pr-0">
                  <v-btn elevation="1" color="primary" @click="addApprovalRule()">Nova Regra DE APROVAÇÃO</v-btn>
                </div>
                <v-row class="mt-1 ml-1 mr-1">
                  <v-col class="cols-md-12">
                    <v-alert class="mt-5" border="left" dense colored-border type="warning"
                    >Este tipo de solicitação ainda não possui regras de aprovação cadastradas!
                    </v-alert>
                  </v-col>
                </v-row>
              </div>
              <div v-else>
                <div class="text-right mb-5 pr-0" v-if="!tabsLoading">
                  <v-btn elevation="1" color="primary" @click="addApprovalRule()">Nova Regra De Aprovação</v-btn>
                </div>
                <v-expansion-panels
                    multiple
                    v-for="(approval_rule, index_approval) in approval_rules_list"
                    :key="index_approval"
                    class="ma-2"
                >
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <v-btn class="form-title" :retain-focus-on-click="false" :ripple="false" color="primary" text
                      >{{ approval_rule.values.description ? approval_rule.values.description : "Nova regra de aprovação" }}
                      </v-btn>
                    </v-expansion-panel-header>
                    <v-divider></v-divider>
                    <v-expansion-panel-content>
                      <ApprovalRules
                          :selectedId="selectedId"
                          :approval_rules_list="approval_rules_list"
                          :index_approval="index_approval"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </v-tab-item>
          </v-tabs>
          <v-col class="text-right">
            <v-btn elevation="1" @click="closeDialog" class="ma-1">FECHAR</v-btn>
            <v-btn :loading="btn_loading || tabsLoading" elevation="1" color="primary" @click="updateSolicitationTypeItem" class="ma-1"
              >SALVAR</v-btn
            >
          </v-col>
        </v-card>
      </div>
    </v-dialog>
  </v-row>
  </v-form>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import ListConditionsComponent from "../solicitationType/ListConditionsComponent.vue";
import ListActionsComponent from "../solicitationType/ListActionsComponent.vue";
import ApprovalRules from "./ApprovalRules.vue";
import ValidatorRules from "./ValidatorRules.vue";

export default {
  components: {
    ValidatorRules,
    ListConditionsComponent,
    ListActionsComponent,
    ApprovalRules
  },
  props: ["selectedId"],
  data() {
    return {
      tabRules: [
        {
          name: "Notificações",
          icon: "mdi-account"
        },
        {
          name: "Validadores",
          icon: "mdi-account"
        },
        {
          name: "Aprovadores",
          icon: "mdi-account"
        }
      ],
      dialogRules: true,
      tab: null,
      show: false,
      rules_list: [],
      approval_rules_list: [],
      validator_rules_list: [],
      showApproval: false,
      btn_loading: false,
      solicitationType: {},
      tabsLoading: true,
      solicitationName: "",
      expandedPanels: [],
      loadRemoveRules: false,
      actionsKey: 0
    };
  },

  async mounted() {
    try {
      await Promise.all([
        this.loadSolicitationConditions(),
        this.loadRules({ id: this.selectedId }),
        this.loadApprovalRules({ id: this.selectedId }),
        this.loadValidatorsRules({ id: this.selectedId }),
        this.loadProvider(),
        this.loadSelectedSolicitationType({ id: this.selectedId }),
      ]);

      if (this.selected_solicitation_type) {
        this.solicitationType = this.selected_solicitation_type.data;
        this.solicitationName = this.solicitationType.display_name;
      }

      this.rules_list = this.rules;
      this.approval_rules_list = this.solicitationTypeApprovalRules;
      this.validator_rules_list = this.solicitationTypeValidatorRules;
      this.tabsLoading = false;
    } catch (error) {
      await this.$swal("Oops...", error.message, "error");
    }
  },
  computed: {
    ...mapState("rules", ["rules", "solicitation_conditions"]),
    ...mapState("approvalRules", ["solicitationTypeApprovalRules","solicitationTypeValidatorRules"]),
    ...mapState("solicitationType", ["selected_solicitation_type", "solicitationType_update"])
  },

  methods: {
    ...mapActions("rules", ["loadRules", "loadSolicitationConditions"]),
    ...mapActions("approvalRules", ["loadApprovalRules","loadValidatorsRules"]),
    ...mapActions("provider", ["loadProvider"]),
    ...mapActions("solicitationType", ["loadSelectedSolicitationType", "updateSolicitationTypeRules"]),
    ...mapMutations({ setRules: "rules/setRules" }),
    ...mapMutations({ setApprovalRules: "approvalRules/setApprovalRules" }),
    ...mapMutations({ setValidatorRules: "approvalRules/setValidatorRules" }),

    createTitle(rule) {
      const selectedConditions = rule?.conditions[0];
      const condition = selectedConditions.condition === "in" ? "for" : "não for";
      let conditionValue = "";
      let conditionName = "";

      if (Array.isArray(this.solicitation_conditions) && rule.conditions[0].type) {
        this.solicitation_conditions.forEach(condition => {
          if (selectedConditions.type === condition.value) {
            condition.values.forEach(value => {
              if (value.id === selectedConditions.type_value) {
                conditionValue = value.display_name;
                conditionName = condition.name;
                return true;
              }
            })
          }
        });

        return `Quando ${conditionName} ${condition} ${conditionValue}`;
      } else {
        return 'Nova Notificação';
      }
      return '';
    },

    addRule() {
      this.tabsLoading = true;
      this.rules_list.push({
        conditions: [{ type: "", condition: "", type_value: "" }],
        actions: [{ type: "", fields: {} }]
      });
      this.setRules(this.rules_list);
      this.tabsLoading = false;
    },

    removeRules(index) {
      this.loadRemoveRules = true;
      this.expandedPanels = this.expandedPanels.filter((panelIndex) => panelIndex !== index);
      if (index > -1) {
        this.rules_list.splice(index, 1); // Remove a regra da lista
      }
      this.setRules(this.rules_list); // Lógica para salvar regras atualizadas
      this.actionsKey++;
      this.loadRemoveRules = false;
    },

    closeDialog() {
      this.$emit("closeDialog", false);
    },

    addApprovalRule() {
      this.approval_rules_list.push({
        values: {
          conditions: [
            {
              condition: "",
              type: "",
              type_value: ""
            }
          ],
          qt_approvers: "",
          approval_limit: "",
          description: "",
          action_message: "",
          action_subject: "",
          solicitation_type_id: this.selectedId
        },
        approvers: []
      });
      this.setApprovalRules(this.approval_rules_list);
    },

    addValidatorRule() {
      this.validator_rules_list.push({
        values: {
          conditions: [
            {
              condition: "",
              type: "",
              type_value: ""
            }
          ],
          description: "",
          solicitation_type_id: this.selectedId
        },
        validators: []
      });
      this.setValidatorRules(this.validator_rules_list);
    },

    async updateSolicitationTypeItem() {
      this.btn_loading = true;
      if (this.$refs.validForm.validate()) {
        const body = {
          solicitation_type_id: this.solicitationType.id,
          notifications: this.rules_list,
          validators: this.validator_rules_list,
          approvers: this.approval_rules_list,
        };

        await this.updateSolicitationTypeRules(body).catch(error => {
          this.$swal("Oops...", error.message, "error");
        });

        if (!this.solicitationType_update.success) {
          this.$swal("Oops ...", this.solicitationType_update.message, "warning");
          this.btn_loading = false;
        } else {
          this.btn_loading = false;
          this.$swal({
            icon: "success",
            title: this.solicitationType_update.message,
            showCancelButton: true,
            confirmButtonText: "Ok",
            cancelButtonText: "Listagem",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#445E93",
            showLoaderOnConfirm: true
          }).then(result => {
            if (!result.value) {
              this.dialogRules = false;
              this.closeDialog();
            }
          });
        }
      } else{
        await this.$swal("Oops ...", 'Verifique os campos obrigatórios', "warning");
      }
      this.btn_loading = false;
    }
  },

  beforeDestroy() {
    this.rules_list = [];
    this.setRules(this.rules_list);
  }
};
</script>
<style scoped>
.form-title {
  background: white;
  justify-content: flex-start;
}

.form-title::before {
  justify-content: flex-start;
  background: white;
}

.selector-col {
  text-align: center;
  background: transparent;
}
</style>
