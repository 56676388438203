<template>
  <div>
    <v-row>
      <v-col cols="12" class="py-0">
        <v-autocomplete
          multiple
          label="Selecione os marcadores"
          outlined
          append-icon=""
          deletable-chips
          item-value="id"
          item-text="name"
          dense
          :items="flagList"
          small-chips
          clearable
          :rules="[]"
          v-model="flagSelected"
          autocomplete="off"
          :loading="loading"
          @change="updateFields"
        >
        </v-autocomplete>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapMutations, mapGetters, mapState, mapActions } from "vuex";
import isEmpty from "lodash/isEmpty";

export default {
  components: {},
  props: ["index", "index_actions", "selectedId"],
  data() {
    return {
      loading: true,
      flagSelected: [],
      flagList: []
    };
  },
  async mounted() {
    let rules = this.rules[this.index].actions[this.index_actions].fields;
    await this.loadFlags().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
    this.flagList = this.flag_list;
    if (!isEmpty(rules.flags)) {
      this.flagSelected = rules.flags;
    }
    this.loading = false;
  },
  computed: {
    ...mapState("rules", ["flag_list", "rules"]),
  },
  methods: {
    ...mapActions("rules", ["loadFlags"]),
    ...mapMutations({ setRules: "rules/setRules" }),

    updateFields() {
      this.rules[this.index].actions[this.index_actions].fields.flags = this.flagSelected;
      this.setRules(this.rules);
    },
  }
};
</script>
